html, body {
  height: 100%; /* Ensures the full height of the viewport is available */
  margin: 0; /* Removes default margin */
  display: flex; /* Enables flexbox properties */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  background: #000; /* Optional: Sets background color of the entire page */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr); /* 8 columns */
  grid-template-rows: auto auto auto; /* Auto-adjusting row heights */
  gap: 10px;
  max-width: 60vw; /* Sets maximum width to 90% of the viewport width */
  height: auto; /* Adjust height to auto if you want it to grow with content */
  min-height: 100vh; /* Minimum height to fill the viewport height */
  padding: 10px;
  box-sizing: border-box;
}

.grid-item {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.grid-item img {
  width: 100%;       /* Make the image fill the width of the container */
  height: 100%;      /* Make the image fill the height of the container */
  object-fit: scale-down; /* Cover the container while preserving aspect ratio */
  display: block;    /* Remove any extra space below the image */
  transition: transform 0.3s ease;
  cursor: pointer;
}

.grid-item:hover img {
  transform: scale(1.05); /* Scales up the image by 5% when hovered */
}

/* First row */
.item-1 { grid-column: 1 / 3; grid-row: 1 / 2; } /* spans 2 columns */
.item-2 { grid-column: 3 / 5; grid-row: 1 / 2; } /* spans 2 columns */
.item-3 { grid-column: 5 / 6; grid-row: 1 / 2; } /* spans 1 column */
.item-4 { grid-column: 6 / 7; grid-row: 1 / 2; } /* spans 1 column */
.item-5 { grid-column: 7 / 9; grid-row: 1 / 2; } /* spans 2 columns */


/* Second row */
.item-6 { grid-column: 1 / 3; grid-row: 2 / 3; } /* spans 2 columns and 2 rows */
.item-7 { grid-column: 1 / 3; grid-row: 3 / 4; } /* spans 2 columns and 2 rows */
.item-8 { grid-column: 3 / 7; grid-row: 2 / 4; } /* spans 4 columns and 2 rows, the big center block */
.item-9 { grid-column: 7 / 9; grid-row: 2 / 4; } /* spans 2 columns and 2 rows */
.mobile-item-9 { grid-column: 7 / 9; grid-row: 2 / 4; } /* spans 2 columns and 2 rows */

/* Third row */
.item-10 { grid-column: 1 / 5; grid-row: 4 / 5; } /* spans 4 columns */
.item-11 { grid-column: 5 / 8; grid-row: 4 / 5; } /* spans 2 columns */
.item-12 { grid-column: 8 / 9; grid-row: 4 / 5; } /* spans 1 column */
/* Note that item 10 does not take up the full width of two columns */

/* Adjustments for the height might be needed */
@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.grid-item.item-6 ,.grid-item.item-7   {
  animation: slideFromLeft 0.8s ease-out forwards;
}

.grid-item.item-9 {
  animation: slideFromRight 0.8s ease-out forwards;
}

.grid-item.mobile-item-9 {
  animation: slideFromRight 0.8s ease-out forwards;
}

.grid-item.item-1,.grid-item.item-2,.grid-item.item-3,.grid-item.item-4,.grid-item.item-5 {
  animation: slideFromTop 0.8s ease-out forwards;
}

.grid-item.item-10,.grid-item.item-11,.grid-item.item-12 {
  animation: slideFromBottom 0.8s ease-out forwards;
}
@media (min-width: 768px) {
  .mobile-item-9{
    display: none;
  }
  
}
@media (max-width: 768px) {

  .grid-container {
    display: grid;
    grid-template-columns: repeat(9, 1fr); /* 8 columns */
    grid-template-rows: auto auto auto auto; /* Auto-adjusting row heights */
    gap: 2px;
    max-width: 80vw; /* Sets maximum width to 90% of the viewport width */
    height: 5px; /* Adjust height to auto if you want it to grow with content */
    min-height: 80vh; /* Minimum height to fill the viewport height */
    padding: 10px;
    box-sizing: border-box;
  }
  .grid-item.item-9{
    display: none;
  }
  .grid-item.item-2,.grid-item.item-3,.grid-item.item-4, .grid-item.item-5,.grid-item.item-12
  {
    display: none;
  }
  .item-1 {
    grid-column: 1 / 4; /* Spans from column line 1 to 4, i.e., three columns */
    grid-row: 1; /* First row */

}

.item-6 {
    grid-column: 4 / 7; /* Spans from column line 4 to 7, i.e., three columns */
    grid-row: 1; /* First row */

}

.item-7 {
    grid-column: 7 / 10; /* Spans from column line 7 to 10, i.e., three columns */
    grid-row: 1; /* First row */

}

.item-8 {
  grid-column: 1 / 10; /* Spans the next three columns, corrected to place in top row */
  grid-row: 2; /* First row */
}
.item-9 {
  grid-column: 8 / 10; /* Spans the next three columns, corrected to place in top row */
  grid-row: 3; /* First row */
}

.mobile-item-9{
  grid-column: 8 / 10; /* Spans the next three columns, corrected to place in top row */
  grid-row: 3; /* First row */
}
.item-10 {
  grid-column: 1 / 5; /* Spans the next three columns, corrected to place in top row */
  grid-row: 3; /* First row */
}
.item-11 {
  grid-column: 5 / 8; /* Spans the next three columns, corrected to place in top row */
  grid-row: 3; /* First row */
}
}


/* video */

.grid-item {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.grid-item:hover .overlay {
  opacity: 1;
}

.play-icon {
  font-size: 2rem;
  color: white;
}

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-popup-content {
  position: relative;
  background: rgb(0, 0, 0);
  padding: 1rem;
  border-radius: 5px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
